@mixin flex {
  display: flex;
}
@mixin flexspb {
  justify-content: space-between;
}
@mixin displayflex {
  display: flex;
  flex-wrap: wrap;
}
@mixin jsc {
  justify-content: center;
}
@mixin alc {
  align-items: center;
}
@mixin dnone {
  display: none;
}
@mixin grey-btn {
  border-style: solid;
  border: 4px solid $shadegrey;
  border-radius: 15px;
  box-shadow: 0px 5px 5px 0px $btnshadow;
  //box-shadow: 0px 0px 2px 0px  $btnshadow;
  max-width: 424px;
  width: 100%;
  height: 69px;
  background: $grey;
  font-family: $Roboto;
  font-weight: normal;
  font-size: 20px;
  color: $white;
  margin: auto;
  outline: none;
  transition: border 0.5s ease;
  padding-top: 6px;
  cursor: pointer;
  @include bp($tab) {
    height: 50px;
    font-size: 16px;
    border: 3px solid $white;
  }
  &:hover {
    border-color: $themelight;
  }
}
@mixin log-input {
  display: block;
  font-family: $Roboto;
  font-weight: normal;
  box-shadow: 0px 5px 5px 0px $outershadow;
  border: 3px solid $intborder;
  border-radius: 15px;
  font-size: 22px;
  height: 70px;
  width: 100%;
  max-width: 432px;
  color: $inputcolor;
  padding: 22px;
  margin: 8px auto;
  outline: none;
  @include bp($tab) {
    height: 50px;
    font-size: 18px;
  }
  @media screen and (max-width:480px) {
    font-size: 16px;
  }
  &::-webkit-input-placeholder {
    color: $inputcolor;
    font-size: 22px;
    font-family: $Roboto;
    font-weight: normal;
    @include bp($tab) {
      font-size: 18px;
    }
    @media screen and (max-width:480px) {
      font-size: 16px;
    }
  }
}
@mixin posicen {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@mixin fontSetter($fsize, $lheight: normal, $lspacing: normal, $textt: unset, $color: $theme, $font-family: $Roboto) {
  font-size: $fsize;
  line-height: $lheight;
  letter-spacing: $lspacing;
  font-family: $font-family;
  text-transform: $textt;
  color: $color;
  @content;
}
@mixin bp($point) {
  @if $point ==mini {
    @media screen and (max-width: 992px) {
      @content;
    }
  }
  @else if $point ==tab {
    @media screen and (max-width: 768px) {
      @content;
    }
  }
  @else if $point ==mob {
    @media screen and (max-width: 576px) {
      @content;
    }
  }
  @else {
    @media screen and (max-width: $point) {
      @content;
    }
  }
}
@mixin medfontset($med, $font, $line) {
  @include bp($med) {
    font-size: $font;
    line-height: $line;
    @content;
  }
}
@mixin fontset($font, $line) {
  font-size: $font;
  line-height: $line;
  @content;
}
@mixin imgabso {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@mixin overell($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
