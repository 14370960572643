.container {
  &.description {
    max-width: 1820px;
    width: 100%;
  }
}
.sub_header {
  background-color: #00568B;
  padding: 10px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    li {
      margin-bottom: 0;
      a {
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
      }
    }
  }
}
.home {
  &_banner {
    padding: 60px 20px;
    background-size: cover;
    display: flex;
    align-content: center;
    background-color: #fff;
    border-bottom: 1px solid #0000004d;
    .container {
      max-width: 1560px;
      margin: auto;
      text-align: center;
    }
    &_left {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      width: 45%;
      &_wrp {
        max-width: 792px;
        margin: auto;
        h1 {
          font-size: 38px;
          font-weight: 500;
          line-height: 61px;
          margin: auto;
          max-width: calc(100% - 30px);
          margin-bottom: 20px;
        }
      }
    }
    &_right {
      width: 55%;
    }
    &_wrp {
      h2 {
        font-size: 32px;
        color: #fff;
        opacity: 0.7;
        font-weight: 600;
        line-height: normal;
      }
    }
    &_search {
      max-width: 1080px;
      width: calc(100% - 30px);
      margin: 0 auto;
      &_radio {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 20px;
        &_left {
          width: calc(100% - 141px);
        }
        .more-filter {
          width: 109px;
          padding-top: 10px;
          display: flex;
          align-items: flex-start;
        }
      }
      &_morefilter {
        max-width: 1080px;
        width: calc(100% - 30px);
        margin: 0 auto;
        margin-top: 10px;
        .more-filters {
          flex-wrap: wrap;
          >div {
            @include bp(mob) {
              width: 100%;
              flex-basis: 100%;
              max-width: 100%;
            }
            .async-select__control .async-select__placeholder {
              margin-left: 20px;
              @include bp(tab) {
                margin-left: 10px;
                font-size: 18px;
              }
            }
          }
        }
      }
      &_demo {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
      }
      &_footer {
        margin-top: 130px;
        &_left {
          h4 {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
        &_right {
          border: 1px solid #005287;
          background-color: #F3FAFF;
          width: 273px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }
          img {
            width: 49px;
          }
          svg {
            color: #005287;
          }
        }
      }
    }
  }
  &_popular {
    max-width: 1514px;
    margin: auto;
  }
  &_popular {
    max-width: 1514px !important;
    padding: 49px 20px !important;
    &_wrp {
      &_item {
        width: 32%;
        border: 1px solid #8099FF;
        background-color: #FDFEFF;
        padding: 40px 60px;
        border-radius: 4px;
        h4 {
          margin-bottom: 35px;
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          display: flex;
          align-items: center;
          gap: 15px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 100%;
            margin-bottom: 24px;
            a {
              display: inline-block;
              font-size: 20px;
              font-weight: 400;
              line-height: 30px;
              transition: all 0.3s ease-in-out;
              &:hover {
                text-decoration: underline;
                font-weight: 500;
                padding-left: 10px;
              }
            }
          }
        }
        &:nth-child(2) {
          border-color: #FF8A00;
          background-color: #FFFCF9;
        }
        &:nth-child(3) {
          border-color: #B500E3;
          background-color: #FEF9FF;
        }
      }
    }
  }
  &_features {
    max-width: 1514px !important;
    padding: 49px 20px !important;
    padding-bottom: 100px !important;
    &_wrp {
      &_item {
        width: 25%;
        text-align: center;
        img {
          margin: 0 auto;
          margin-bottom: 21px;
        }
        h3 {
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          color: #000000;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #707070;
        }
      }
    }
  }
  &_benefit {
    background-color: #F8FAFF;
    padding: 89px 0;
    display: flex;
    .section_title {
      width: 100%;
      max-width: calc(100% - 70%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_wrp {
      display: flex;
      flex-wrap: wrap;
      max-width: 70%;
      width: 100%;
      &_item {
        width: 50%;
        max-width: 544px;
        display: flex;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        padding: 16px 20px;
        padding-right: 50px;
        margin-bottom: 30px;
        img {
          width: 100px;
        }
        &_content {
          h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            color: #2554BD;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #707070;
          }
        }
      }
    }
  }
  &_features {
    &.libraries {
      .home_features_wrp_item_content {
        h3 {
          font-size: 20px;
          font-weight: 500;
          line-height: 23px;
          margin-bottom: 15px;
        }
      }
    }
  }
  &_ourtools {
    background-color: #F9F9F9;
    .container {
      max-width: 1800px !important;
      padding: 60px 50px;
    }
    &_item {
      padding: 0 15px;
      &_wrp {
        padding: 16px;
        background-color: #fff;
        box-shadow: 0px 2px 10px 0px #0000001F;
        border-radius: 6px;
        margin-bottom: 5px;
      }
      &_top {
        margin-bottom: 10px;
        &_image {
          width: 80px;
          height: 80px;
          background-color: #F9F9F9;
          padding: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &_title {
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
        }
      }
      &_content {
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 10px;
        }
        a {
          color: #00A0DC;
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
  &_blog {
    max-width: 1784px !important;
    padding: 60px 0;
    &_wrp {
      display: flex;
      flex-wrap: wrap;
    }
    &_item {
      padding: 0 10px;
      width: 33.33%;
      &_img {
        margin-bottom: 10px;
        img {
          height: 331px;
          width: 100%;
          object-fit: cover;
        }
      }
      &_content {
        h4 {
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 10px;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
        }
      }
    }
  }
}
.section_title {
  text-align: center;
  margin-bottom: 32px;
  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }
}
// More Filter
.more-filter {
  color: #00a0dc;
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0;
  margin-left: 15px;
  svg {
    height: 24px;
    width: 24px;
    margin-left: 5px;
  }
}
.MuiTabs-root {
  .MuiTab-root {
    font-size: 18px;
    font-weight: 700;
    border: none;
    color: #707070;
    &.active {
      // Contract Type
      color: #00A0DC;
    }
  }
}

.search-box {
  position: relative;
  .css-py1ukw-MuiInputBase-root-MuiOutlinedInput-root fieldset {
    border: none !important;
  }
  input {
    @include bp(tab) {
      padding-left: 22px;
      font-size: 18px;
    }
  }
  .search_icon {
    height: 100%;
    z-index: 1;
    cursor: pointer;
    width: 25px;
    justify-content: center;
    margin-left: 10px;
    svg {
      stroke: gray;
    }
    &:hover {
      svg {
        stroke: #005287;
      }
    }
    .notranslate {
      display: none;
    }
  }
  .search-result {
    position: absolute;
    top: 97%;
    left: 0;
    width: 100%;
    background: #fff;
    border-top: none;
    border-radius: 4px;
    z-index: 999;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 -3px 15px 0px #0000002E;
    &.hide-class {
      display: none;
    }
    .search-item {
      padding: 6px 10px;
      color: #303030;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.2px;
      word-wrap: break-word;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 0;
      &:hover {
        background: #f5f5f5;
      }
      svg {
        margin-right: 8px;
        width: 20px;
      }
      p {
        width: calc(100% - 30px);
        color: #303030;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
        word-wrap: break-word;
        text-transform: capitalize;
        color: #0e7ea9;
      }
    }
  }
}

.home_banner_search {
  .search_icon {
    width: 30px;
  }
}