.login {
    padding-top: 60px;
    padding: 45px;
    height: 100vh;
    &_bottom {
        display: flex;
        gap: 20px;
        height: 82vh;
        &_left {
            border-right: 1px solid rgba(0, 0, 0, 0.3);
            width: calc(50%);
            padding-right: 20px;
            &_wrp {
                max-width: 480px;
                margin: auto;
            }
        }
        &_right {
            width: calc(50%);
        }
    }
    &_top {
        &_left {
            width: 50%;
            padding-right: 20px;
            margin-bottom: 30px;
            text-align: center;
            a {
                display: block;
                img {
                    width: 300px;
                }
            }
        }
        &_right {
            width: 50%;
            padding-left: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }
}