.faq {
    padding: 56px 0;
    @include bp(mini) {
        padding: 20px 0;
    }
    .faq_heading {
        font-size: 28px;
        line-height: 34px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    &_item {
        border: none !important;
        border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
        button {
            padding: 16px 20px;
            color: #000;
            font-weight: 600;
            font-size: 20px;
            text-transform: capitalize;
            line-height: normal;
            &[aria-expanded="true"] {
                color: #005287;
                background-color: transparent;
                box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
                font-weight: 600;
                font-size: 20px;
                border: none;
                border-bottom: 3px solid #005287;
            }
            span {
                font-weight: 600;
                font-size: 20px;
            }
        }
        &_ans {
            background: #fbfbfb;
            padding: 16px 20px !important;
            font-weight: normal;
        }
    }
}