.library {
    .search-result-head {
        margin-top: 0;
        .search-main {
            padding-bottom: 0;
        }
    }
    .search-result-body {
        height: calc(100vh - 150px);
        margin-bottom: 20px;
    }
    &_list {
        border: 1px solid #D6D6D6;
        margin-bottom: 32px;
        &_header {
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #D4D4D4;
            h6 {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                text-align: left;
            }
            span {
                font-size: 16px;
                font-weight: 600;
                line-height: 30px;
                color: #0f8cb9;
                cursor: pointer;
                &.disable {
                    color: #868686;
                    pointer-events: none;
                    cursor: default;
                }
            }
        }
        &_content {
            padding: 20px;
            ul {
                -webkit-column-count: 3;
                -moz-column-count: 3;
                column-count: 3;
                li {
                    cursor: default;
                    div {
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 30px;
                        padding: 0 20px;
                        span {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 30px;
                            text-decoration: underline;
                            color: #0096C9;
                            margin-right: 2px;
                            cursor: pointer;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            text-transform: capitalize;
                            word-break: break-all;
                        }
                        p {
                            color: #707070;
                            cursor: pointer;
                        }
                        svg {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .selected-filter {
        margin-top: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        >ul {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            li {
                border-radius: 4px;
                display: flex;
                align-items: flex-start;
                gap: 3px;
                margin-bottom: 0;
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                }
                h6 {
                    font-size: 14px;
                    color: #303030;
                    line-height: 28px;
                }
                .selected {
                    // Product
                    color: #005287;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 12px;
                    word-wrap: break-word;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: rgba(185, 229, 251, 0.4);
                    height: 28px;
                    padding: 8px 8px;
                    border-radius: 4px;
                    span {
                        margin-right: 5px;
                        display: block;
                    }
                    svg {
                        cursor: pointer;
                    }
                }
            }
        }
        button {
            margin-left: 10px;
            width: 80px;
            min-width: 80px;
            height: 27px;
        }
    }
    .head-right {
        position: unset;
        margin-bottom: 10px;
        margin-right: 0;
        display: flex;
        justify-content: flex-end;
    }
}
.custom_client {
    .MuiInputLabel-root {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: left;
        margin-bottom: 10px;
        color: #303030;
    }
}