.navbar {
    position: sticky;
    top: 0;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    z-index: 999999;
    background-color: #fff;
    display: flex;
    align-items: center;
    &_wrp {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        width: 100%;
        .logo {
            width: 100%;
            max-width: 190px;
            padding: 15px 0;
            img {
                display: block;
                object-fit: contain;
            }
        }
        &_left {
            width: 100%;
        }
        .aftlog {
            display: block;
            position: relative;
            margin-bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
            a {
                font-size: 14px;
            }
            .dropdown-menu {
                position: absolute;
                z-index: 1000;
                display: none;
                margin: 0;
                font-size: 1rem;
                color: #212529;
                text-align: left;
                list-style: none;
                background-color: #fff;
                right: 0;
                left: 0;
                width: 100%;
                li {
                    margin-bottom: 0;
                    padding: 10px;
                    &:hover {
                        background-color: #03639d;
                        a {
                            color: #fff !important;
                        }
                    }
                    a {
                        text-decoration: none;
                        color: #212529;
                    }
                }
            }
            &:hover {
                .dropdown-menu {
                    top: 45px;
                    margin: auto;
                    display: block;
                    margin-top: 0;
                    box-shadow: 1px 2px 5px rgba(0, 0, 0, .1411764706);
                    -webkit-animation-name: down-animate;
                    animation-name: down-animate;
                    -webkit-animation-duration: .5s;
                    animation-duration: .5s;
                }
            }
        }
        &_right {
            gap: 26px;
            @include bp(1420px) {
                gap: 20px;
            }
            &_search {
                position: relative;
                margin: auto;
                margin-left: 0;
                // width: 258px;
                @include bp(1920px) {
                    width: 258px;
                }
                @include bp(1420px) {
                    width: 100%;
                }
                input {
                    font-size: 14px;
                    background-color: #F2F8FB;
                    border-color: #00528780;
                }
                button {
                    position: absolute;
                    top: 0;
                    left: 6px;
                    bottom: 0;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    z-index: 1;
                }
            }
            &_icon {
                display: flex;
                align-items: center;
                gap: 26px;
                @include bp(1420px) {
                    gap: 20px;
                }
            }
        }
    }
    &_nav {
        display: flex;
        height: 100%;
        >li {
            height: 100%;
            margin-right: 24px;
            align-items: center;
            display: flex;
            font-size: 16px;
            @include bp(1420px) {
                margin-right: 15px;
            }
            svg {
                transition: all 0.3s;
            }
            &:hover {
                a {
                    cursor: pointer;
                    svg {
                        transform: rotate(-180deg);
                    }
                }
                .dropdown-menu {
                    top: 41px;
                    display: block;
                    margin-top: 0;
                    box-shadow: 1px 2px 5px rgba(0, 0, 0, .1411764706);
                    animation-name: down-animate;
                    animation-duration: .5s;
                }
            }
            .dropdown-menu {
                position: absolute;
                z-index: 1000;
                padding: 0;
                margin: 0;
                color: #212529;
                text-align: left;
                list-style: none;
                background-color: #fff;
                border: 1px solid rgba(0, 0, 0, .15);
                border-radius: 0.25rem;
                border-radius: 0.25rem;
                width: 250px;
                flex-wrap: wrap;
                height: auto;
                top: 0;
                display: none;
                li a {
                    padding: 10px;
                    margin: 0;
                    width: 100%;
                    font-size: 14px;
                    height: auto;
                    transition: all .2s;
                    display: block;
                    color: #005287;
                    &:hover {
                        background: #005287;
                        color: #fff;
                    }
                }
            }
            .nav_link {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                font-weight: 500;
                color: #303030;
            }
        }
    }
}
@keyframes down-animate {
    0% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}