@import "themes-vars.module";
@import "rsuite/dist/rsuite.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
//Variables import
@import "variable.scss";
//Mixins import
@import "mixins.scss";
//Base file import
@import "base/base.scss";
@import "base/fonts.scss";
@import "base/reset.scss";
@import "base/dynamic";
// page file import
@import "preloader";
@import "header";
@import "footer";
@import "buttons";
@import "icons";
@import "pages/home";
@import "pages/search";
@import "pages/login";
@import "pages/library";
@import "pages/product";
@import "pages/blog";
@import "pages/contact";
@import "pages/faq";
@import "pages/plans";
@import "_sunEditor.scss";
@import "_quilleditor.scss";




iframe#webpack-dev-server-client-overlay {
  display: none !important
}
body {
  background-color: #f7f7f7;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.custom-input {
  // Include Keywords
  color: #909090;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  border: 1px solid #b3b3b3;
  min-height: 38px;
  border-radius: 4px;
  padding: 0 10px;
}
.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1900px;
}
.cominsoon {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #005287;
  opacity: 1;
  img {
    width: 100%;
    max-width: 650px;
    margin: auto;
    margin-bottom: 15px;
  }
  h1 {
    text-align: center;
    font-size: 41px;
    font-weight: bold;
    color: #fff;
  }
}
.custom-select-control {
  .MuiFormLabel-root {
    // Company Name
    color: #909090;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    word-wrap: break-word;
  }
}
.async-select__control {
  height: 52px;
  .async-select__placeholder {
    // Company Name
    color: #909090;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    word-wrap: break-word;
  }
  .async-select__indicator-separator {
    display: none;
  }
  .async-select__dropdown-indicator {
    padding: 8px 2px;
  }
  .async-select__loading-indicator {
    position: absolute;
    right: 0;
    bottom: -4px;
  }
}
body {
  .main-container {
    max-width: 1700px;
    padding-left: 24px;
    padding-right: 24px;
    margin: auto;
    width: 100%;
    padding-top: 15px;
  }
  .group-control {
    .MuiInputBase-root {
      height: 40px;
      svg {
        height: 28px;
        width: 28px;
        min-width: 28px;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: 2px solid #005287;
      legend {
        display: none;
      }
    }
  }
}
#react-doc-viewer {
  #html-renderer {
    padding: 0;
  }
  iframe {
    border: 0;
    margin: 0;
    padding: 0;
  }
}
.gray_bg {
  background-color: #f1f1f1;
  height: calc(100vh - 80px);
  overflow: auto;
}