.blog_item {
    box-shadow: unset !important;
    cursor: pointer;
    &_wrp {
        transition: 0.3s all;
        border-radius: 10px;
        &:hover {
            box-shadow: 1px 3px 6px rgba(0, 0, 0, .1) !important;
            transform: translateY(-10px);
        }
        &_img {
            img {
                transition: 0.3s all;
                height: 180px;
                width: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                object-fit: cover;
            }
            h2.blog_title {
                font-weight: 600;
                font-size: 15px;
                text-transform: capitalize;
                font-family: $Roboto !important;
            }
        }
        .blog_content {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            * {
                font-weight: normal !important;
                font-family: $Roboto !important;
                font-size: 15px !important;
                font-style: initial !important;
                line-height: 1.6;
                text-transform: capitalize;
            }
        }
    }
}
.blog_info {
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;
    padding: 0 12px;
    padding-top: 8px;
    justify-content: space-between;
    div {
        width: 50%;
        font-size: 12px;
        font-weight: 600;
        color: #b8b6b6;
        text-align: left;
        &:last-child {
            text-align: right;
        }
    }
}
.blog_details {
    display: flex;
    gap: 10px;
    padding: 56px 0;
    @include bp(mini) {
        flex-wrap: wrap;
        gap: 20px;
    }
    @include bp(tab) {
        padding: 20px 0;
    }
    &_left {
        width: 75%;
        padding: 0 12px !important;
        border: none !important;
        @include bp(mini) {
            width: 100%;
            padding: 0 !important;
        }
        img {
            border-radius: 13px;
            width: 100%;
            display: block;
        }
        h2.blog-title {
            font-weight: 600;
            text-transform: capitalize;
            font-family: $Roboto !important;
            font-size: 24px !important;
        }
        .blog_content {
            padding: 0;
            p, span, ul, ol {
                font-family: 'Roboto', sans-serif !important;
                font-size: 16px !important;
                text-align: justify !important;
                line-height: 1.6;
                color: #000 !important;
            }
            h1, h2, h3, span, h4, h5, h6 {
                font-family: 'Roboto', sans-serif !important;
                font-size: 18.38px !important;
                line-height: 1.6;
                color: #000 !important;
                font-weight: 700;
            }
        }
    }
    &_right {
        width: 25%;
        padding: 0 12px;
        @include bp(mini) {
            width: 100%;
            padding: 0 !important;
        }
        .popular_post {
            position: -webkit-sticky;
            position: sticky !important;
            align-self: flex-start;
            top: 92px;
            z-index: 1;
            @include bp(tab) {
                position: unset !important;
            }
            h4 {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 16px;
                @include bp(tab) {
                    font-size: 22px;
                }
            }
            &_item {
                padding: 8px;
                border-left: 7px solid #005287 !important;
                box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
                margin-bottom: 8px;
                border-top-left-radius: 4.8px;
                border-bottom-left-radius: 4.8px;
                h6 {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}