.contactus {
    display: flex;
    padding: 56px 0;
    align-items: center;
    justify-content: center;
    @include bp(mini) {
        padding: 20px 0;
    }
    @include bp(tab) {
        flex-wrap: wrap;
    }
    gap: 10px;
    &_left {
        width: 50%;
        display: grid;
        @include bp(tab) {
            width: 100%;
            justify-content: center;
            text-align: center;
        }
        h2 {
            color: #212529;
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 5px;
            margin-top: 0;
        }
        p {
            font-size: 15px;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 16px;
            a {
                text-decoration: underline;
            }
        }
    }
    &_right {
        width: 50%;
        @include bp(tab) {
            width: 100%;
        }
        .contactus_form {
            background: linear-gradient(45deg, #005287, #033e62);
            border-radius: 20px;
            padding: 48px;
            @include bp(tab) {
                padding: 20px;
            }
            h4 {
                font-size: 24px;
                list-style: 28px;
                font-weight: 700 !important;
                color: #fff;
                margin-bottom: 20px;
            }
        }
        label {
            text-align: left;
            width: 100%;
            display: block;
            color: #fff;
            font-weight: 500;
        }
        input {
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            border: 1px solid #ced4da;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0.25rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }
        button {
            width: 100%;
            background-color: #fff !important;
            color: #005287 !important;
            font-weight: 600;
            transition: all 0.4s;
            padding: 10px 20px;
            display: inline-block;
            border: 2px solid #005287;
        }
    }
}