.search-main {
  display: flex;
  flex-direction: column;
  // height: calc(100vh - 110px);
  padding-bottom: 15px;
  .search-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-left {
      display: flex;
      align-items: center;
      .search-box {
        // border-radius: 4px;
        // border:  1px solid #005287;
        // background-color: #fff;
      }
      span {
        color: #8e8e8e;
        font-size: 16px;
        font-weight: 500;
        word-wrap: break-word;
        margin-left: 15px;
        text-transform: capitalize;
      }
    }
    .MuiInputBase-input {
      padding: 5px 15px;
      // Representation & Warranties
      color: #505050;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 1px;
      word-wrap: break-word;
      text-overflow: ellipsis;
      &::placeholder {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: normal;
      }
    }
    .group-control {
      width: 430px;
    }
  }
  .searchtab_content {
    // flex: 1;
    height: calc(100vh - 150px);
    overflow: auto;
    >div.MuiBox-root {
      height: 100%;
    }
  }
}
.search-result-head {
  margin-top: 12px;
  >ul {
    display: flex;
    align-items: center;
    >li {
      color: black;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.6px;
      word-wrap: break-word;
      margin-right: 20px;
      position: relative;
      &:last-child {
        &::after {
          content: unset;
        }
      }
      &::after {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #000;
        position: absolute;
        right: -11px;
        top: 0px;
        bottom: 0;
        margin: auto;
      }
      a {
        color: #0098ee;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.6px;
        word-wrap: break-word;
        text-decoration: none;
      }
    }
  }
  .filters-main {
    margin-top: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    .filter-row {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: calc(100% - 163px);
      .async-select__control {
        height: 36px;
        border-color: #b3b3b3 !important;
        box-shadow: none !important;
        .async-select__placeholder {
          // Contract Type
          color: #505050;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: normal;
        }
      }
      .filter-col {
        width: auto;
        max-width: 190px;
        min-width: 151px;
        input {
          &::placeholder {
            font-size: 12px;
            color: #000;
          }
        }
        .rs-picker-default .rs-picker-toggle {
          &.rs-btn {
            border-color: #b3b3b3;
            border-radius: 4px;
            font-size: 12px;
            z-index: 0;
            background-color: #F9F9F9 !important;
          }
        }
        .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
          color: #333333 !important;
        }
      }
    }
    .sort-by {
      width: 163px; // margin-left: 30px;
      .filter-row {
        width: 100%;
        .filter-col {
          min-width: 163px;
        }
      }
    }
  }
  .selected-filter {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    >ul {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      li {
        border-radius: 4px;
        display: flex;
        align-items: flex-start;
        gap: 3px;
        margin-bottom: 0;
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }
        h6 {
          font-size: 14px;
          color: #303030;
          line-height: 28px;
        }
        .selected {
          // Product
          color: #005287;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          word-wrap: break-word;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: rgba(185, 229, 251, 0.4);
          height: 28px;
          padding: 8px 8px;
          border-radius: 4px;
          span {
            margin-right: 5px;
            display: block;
          }
          svg {
            cursor: pointer;
          }
        }
      }
    }
    button {
      margin-left: 10px;
      width: 80px;
      min-width: 80px;
      height: 27px;
    }
  }
}
.search-result-body {
  background-color: #ffffff;
  padding: 0x;
  border: 1px solid #E4E4E4;
  padding: 20px;
  overflow: hidden;
  display: flex;
  // flex-grow: 1;
  height: 100%;
  width: 100%;
  .left {
    width: 100%;
    padding-right: 10px;
    overflow: auto;
    height: 100%;
    overflow: auto;
    >ul>li {
      border: 1px solid transparent;
      padding: 15px;
      position: relative;
      &.selected {
        border: 1px solid #00a0dc;
        padding: 15px;
        border-radius: 4px;
      }
      span {
        &.remove {
          position: absolute;
          right: 2px;
          top: 3px;
        }
      }
    }
  }
  ul {
    list-style: none;
    li {
      cursor: pointer;
      margin-bottom: 12px;
      .title {
        display: flex;
        align-items: center;
        .number {
          color: #303030;
          font-size: 16px;
          font-weight: 600;
          word-wrap: break-word;
        }
        h5 {
          color: #303030;
          font-size: 16px;
          font-weight: 600;
          text-decoration: underline;
          word-wrap: break-word;
          margin-left: 8px;
          text-transform: capitalize;
        }
      }
      .doc_content {
        padding-left: 17px;
        .tags-main {
          margin-top: 10px;
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              margin-bottom: 0;
              border-right: 1px solid #000;
              margin-right: 5px;
              padding-right: 5px;
              p {
                font-size: 14px !important;
              }
              &:last-child {
                border-right: none;
              }
              p {
                margin-bottom: 0;
                font-size: 12px;
              }
            }
          }
        }
      }
      .description {
        margin-top: 12px;
        line-height: 21px;
        max-height: 66px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-size: 14px;
        p {
          color: #505050;
          font-weight: 400;
          margin: 0;
          word-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: flex;
          // * {
          //   text-overflow: ellipsis;
          //   -webkit-box-orient: vertical;
          //   -webkit-line-clamp: 1;
          //   overflow: hidden;
          //   display: -webkit-inline-box;
          //   width: auto;
          // }
          // b {
          //   white-space: nowrap;
          //   overflow: unset;
          //   margin: 0 2px;
          // }
        }
      }
      .definitions {
        margin-top: 12px;
        div {
          color: #505050;
          font-size: 14px;
          font-weight: 400;
          word-wrap: break-word;
          margin: 0;
          width: 100%;
          line-height: 21px;
          max-height: 66px;
          /* background-color: gainsboro; */
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}
.left.preview-open {
  width: 50%;
  >ul {
    padding-right: 10px;
  }
}
.right {
  width: 50%;
  border-left: 1px solid #ccc;
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  &_contracttitle {
    text-align: center;
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #react-doc-viewer {
    flex: 1;
  }
  // .client_modal {
  //   position: absolute;
  //   right: 0;
  //   left: 0;
  //   display: flex;
  //   height: 100%;
  //   width: 100%;
  //   align-items: center;
  //   background-color: #00000050;
  //   &_list {
  //     background-color: #fff;
  //     width: calc(100% - 30px);
  //     max-height: 400px;
  //     overflow: auto;
  //     padding: 10px;
  //     margin: 0 auto;
  //     label {
  //       margin: 0;
  //       width: 100%;
  //       justify-content: space-between;
  //     }
  //   }
  // }
}
.MuiTabs-flexContainer {
  position: relative;
}
.head-right {
  position: absolute;
  right: 0;
  ul {
    display: flex;
    align-items: center;
    li {
      margin: 0 8px;
      .preview {
        // Preview
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.24px;
        word-wrap: break-word;
        background-color: #ffffff;
        color: #000;
        height: 28px;
        padding: 0 5px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #000000;
        svg {
          margin-right: 5px;
          height: 20px;
          width: 20px;
        }
        &.active {
          border: 1px solid #005287;
          color: #005287;
          background-color: #E3F5FD;
        }
      }
      .arrow {
        display: flex;
        align-items: center;
        svg {
          height: 24px;
          width: 24px;
          // color: #005287;
          cursor: pointer;
        }
      }
    }
  }
}
.custombtn {
  color: #005287;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.24px;
  word-wrap: break-word;
  background-color: #e3f5fd;
  height: 34px;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #005287;
}
//viewfull
.viewfull {
  display: flex;
  flex-direction: row;
  gap: 20px;
  &_head {
    ul {
      display: flex;
      li {
        color: black;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.6px;
        word-wrap: break-word;
        margin-right: 20px;
        position: relative;
        cursor: default;
        text-transform: capitalize;
        &.active {
          color: #0098EE !important;
          cursor: pointer;
          a {
            color: #0098EE !important;
            cursor: pointer;
          }
        }
        &:last-child {
          &::after {
            content: unset;
          }
        }
        &::after {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #000;
          position: absolute;
          right: -11px;
          top: 0px;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
  &_left {
    width: calc(100% - 393px);
    &_wrp {
      height: 681px;
      margin-bottom: 25px;
      position: relative;
    }
    &_top {
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: #fff;
      &_save {
        position: absolute;
        right: 10px;
        border-bottom: 0 !important;
      }
      >div {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-bottom: 1px solid #00000029;
      }
      .right_contracttitle {
        text-decoration: underline;
      }
      &_resize {
        color: #005287;
        span {
          font-size: 12px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0.6000000238418579px;
          text-align: left;
          background: #E3F5FD;
          color: #005287;
          width: 60px;
          text-align: center;
          padding: 5px;
        }
      }
    }
    &_doc {
      height: 100%;
      max-height: 586px;
      padding: 20px 10px;
      overflow: auto;
      background-color: #fff;
      >div {
        padding: 0 20px;
        height: 100%;
        overflow: auto;
        line-height: normal;
      }
    }
    &_similar {
      background-color: #fff;
      margin-bottom: 20px;
      h3 {
        padding: 0 20px;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
      }
      h4 {
        padding: 15px 20px;
        text-align: left;
        font-weight: 700;
        font-size: 20px;
        color: #101010;
        line-height: normal;
        border-bottom: 1px solid #A8A8A8;
      }
      &_list {
        padding: 15px 0;
        height: 100%;
        max-height: 365px;
        overflow: auto;
        ul {
          padding: 0 20px;
          max-height: 585px;
          overflow: auto;
          li {
            display: flex;
            flex-wrap: nowrap;
            border-bottom: 1px solid #B8B8B840;
            padding: 12px 0;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }
            span {
              font-size: 15px;
              font-weight: 400;
              line-height: 19px;
              color: #005287;
            }
          }
        }
      }
    }
  }
  &_right {
    width: 373px;
    &_meta {
      background-color: #fff;
      margin-bottom: 20px;
      h4 {
        padding: 15px 20px;
        text-align: left;
        font-weight: 700;
        font-size: 20px;
        color: #101010;
        line-height: normal;
        border-bottom: 1px solid #A8A8A8;
      }
      &_list {
        padding: 15px 0;
        ul {
          padding: 0 20px;
          max-height: 585px;
          overflow: auto;
          li {
            display: flex;
            flex-wrap: nowrap;
            border-bottom: 1px solid #B8B8B840;
            padding: 12px 0;
            margin-bottom: 0;
            cursor: default;
            &:last-child {
              border: none;
              &.source_link {
                span {
                  &:last-child {
                    a {
                      display: flex;
                      align-items: center;
                      gap: 2px;
                      color: #005287;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            span {
              width: 50%;
              &:first-child {
                color: #656565;
                font-size: 15px;
                font-weight: 500;
                text-align: left;
              }
              &:last-child {
                font-size: 15px;
                font-weight: 400;
                line-height: 19px;
                color: #005287;
                cursor: pointer;
                text-transform: capitalize;
              }
            }
          }
        }
      }
      &.agreement {
        ul {
          li {
            span {
              width: 100%;
              cursor: pointer;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
.client_assignmodal {
  table {
    tbody {
      tr {
        border: none;
        td {
          border: none;
          &:nth-child(1) {
            padding: 5px 10px;
          }
        }
      }
    }
  }
}
.save_dropdwon {
  position: relative;
  cursor: pointer;
  .save_dropdwon_wrp {
    &.active {
      display: block;
      margin-top: 0;
      animation-name: down-animate;
      animation-duration: 0.5s;
    }
  }
  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &_wrp {
    position: absolute;
    display: none;
    right: 0;
    background: #ffff;
    padding: 0 10px;
    top: 110%;
    box-shadow: 0px 2px 8px 0px #0000001F;
    ul {
      width: 121px;
      li {
        margin-bottom: 0;
        label {
          margin-left: 0;
          justify-content: space-between;
        }
        >span {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 10px 0px;
          font-size: 0.875rem;
          align-items: center;
        }
      }
    }
  }
}
.customlist {
  display: flex;
  width: 100%;
  padding: 2px 5px;
  align-items: flex-start;
  cursor: pointer;
  span {
    padding: 0;
  }
  p {
    width: calc(100% - 29px);
    word-break: break-word;
    font-size: 14px;
    text-transform: capitalize;
  }
}
.main_div {
  input.MuiInputBase-input {
    background-color: #F9F9F9 !important;
    color: #505050 !important;
    &::-webkit-input-placeholder {
      font-size: 12px;
      font-family: "Roboto" !important;
      color: #505050 !important;
    }
  }
  .MuiInputBase-sizeSmall {
    padding-right: 39px !important;
  }
  .MuiAutocomplete-tag {
    display: none;
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
}
.textfield {
  .MuiInputBase-inputSizeSmall {
    font-size: 12px;
  }
}
.singleselect {
  .MuiAutocomplete-listbox {
    li {
      margin-bottom: 0;
    }
  }
}
.taginput {
  width: 151px;
  padding: 0 !important;
  position: relative !important;
  >div>div {
    padding: 0 !important;
    background-color: #fafafa;
    position: relative;
    height: 37px !important;
    min-width: 0;
    width: 151px;
    input {
      padding: 0;
      width: 151px;
      padding-left: 10px;
    }
  }
  fieldset~div {
    display: none !important;
  }
  .MuiOutlinedInput-root-22.MuiOutlinedInput-focused-23 .MuiOutlinedInput-notchedOutline-29, .jss22.jss23 .jss29 {
    border-color: #2196f3 !important;
    border-width: 2px;
  }
}