.plans {
    background-color: #eef1f5;
    padding: 60px 0;
    .switch-wrapper {
        position: relative;
        display: inline-flex;
        margin-bottom: 40px;
        border-radius: 30px;
        background: #fff;
        box-shadow: 0px 0px 24px 0px #00000014;
    }
    .switch-wrapper [type="radio"] {
        position: absolute;
        left: -9999px;
    }
    .switch-wrapper [type="radio"]:checked#quarterly~label[for="quarterly"],
    .switch-wrapper [type="radio"]:checked#yearly~label[for="yearly"] {
        color: #fff !important;
        font-weight: 600;
    }
    .switch-wrapper [type="radio"]:checked#quarterly~label[for="quarterly"]:hover,
    .switch-wrapper [type="radio"]:checked#yearly~label[for="yearly"]:hover {
        background: transparent;
    }
    .switch-wrapper [type="radio"]:checked#quarterly+label[for="yearly"]~.highlighter {
        transform: none;
    }
    .switch-wrapper [type="radio"]:checked#yearly+label[for="quarterly"]~.highlighter {
        transform: translateX(100%);
    }
    .switch-wrapper label {
        font-size: 20px;
        z-index: 1;
        min-width: 135px;
        line-height: 48px;
        cursor: pointer;
        border-radius: 30px;
        transition: color 0.25s ease-in-out;
        text-align: center;
        color: #B0B0B0;
        font-weight: 500;
        @include bp(mini) {
            font-size: 18px;
            min-width: 115px;
            line-height: 38px;
        }
    }
    .switch-wrapper .highlighter {
        position: absolute;
        width: 135px;
        height: 100%;
        border-radius: 30px;
        background: #319AFC;
        transition: transform 0.25s ease-in-out;
        @include bp(mini) {
            width: 115px;
        }
    }
    &_table {
        .chakra-tabs__tab-panel {
            padding: 0;
            overflow: auto;
        }
        .chakra-tabs__tablist {
            flex-wrap: wrap;
            button {
                background-color: #fff;
                min-width: 160px;
                color: #000000;
                font-size: 18px;
                font-weight: 400;
                line-height: 26px;
                border: 1px solid #F9F9F9 !important;
                &[aria-selected=true] {
                    background-color: #319AFC;
                    color: #fff !important;
                }
                @include bp(mini) {
                    min-width: 120px;
                    font-size: 16px;
                }
            }
        }
        .chakra-tabs__tab-panels {
            box-shadow: 0px 0px 12px 0px #00000014;
            background-color: #fff;
        }
        table {
            width: 100%;
            th, td {
                width: 25%;
                min-width: 165px;
            }
            thead {
                tr {
                    border-bottom: 1px solid rgba(230, 233, 245, 1);
                    th {
                        padding: 20px;
                        &:nth-child(1) {
                            position: sticky;
                            left: -2px;
                            z-index: 1;
                            background-color: #fff;
                        }
                        h5 {
                            font-size: 32px;
                            font-weight: 700;
                            line-height: 46px;
                            margin-bottom: 28px;
                            margin-top: 8px;
                            @include bp(mini) {
                                font-size: 25px;
                                font-weight: 700;
                                line-height: normal;
                            }
                        }
                        button {
                            color: #fff;
                            height: 52px;
                            @include bp(mini) {
                                height: 42px;
                            }
                            span {
                                text-shadow: 1px 3px 4px #0000007d;
                                font-size: 16px;
                                @include bp(mini) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
            .starter {
                background: #dbf2fd80;
                h5 {
                    color: #000000;
                }
                button {
                    background: linear-gradient(90deg, #B5CCD8 0%, #90A6B3 100%);
                }
            }
            .standard {
                background: #cee4ff80;
                h5 {
                    color: rgba(28, 114, 194, 1);
                }
                button {
                    background: linear-gradient(90deg, #5EA8FF 0%, #255CEA 100%);
                }
            }
            .premium {
                background: #fff1d280;
                h5 {
                    color: rgba(233, 176, 30, 1);
                }
                button {
                    background: linear-gradient(90.09deg, #F7CF42 0.08%, #E9B01E 99.94%);
                }
            }
            tbody {
                tr {
                    &.heading {
                        td {
                            &:nth-child(1) {
                                background: rgba(248, 248, 248, 1);
                                color: #319AFC;
                                border-bottom: none !important;
                            }
                            &:nth-child(2) {
                                border-bottom: none !important;
                                background-color: #DBF2FD;
                            }
                            &:nth-child(3) {
                                background-color: #CEE4FF;
                                border-bottom: 1px solid rgba(206, 228, 255, 1);
                            }
                            &:nth-child(4) {
                                background: #FFF1D2;
                                border-bottom: 1px solid rgba(255, 241, 210, 1);
                            }
                        }
                    }
                    td {
                        padding: 15px 30px;
                        color: #595959;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: normal;
                        @include bp(mini) {
                            padding: 12px 25px;
                        }
                        img {
                            margin: auto;
                        }
                        &:nth-child(1) {
                            text-align: left;
                            border-bottom: 1px solid #E6E9F5;
                            position: sticky;
                            left: -2px;
                            z-index: 1;
                            background-color: #fff;
                        }
                        &:nth-child(2) {
                            background: #dbf2fd80;
                            border-bottom: 1px solid rgba(230, 233, 245, 1)
                        }
                        &:nth-child(3) {
                            background: #cee4ff80;
                            border-bottom: 1px solid #CEE4FF
                        }
                        &:nth-child(4) {
                            background: #fff1d280;
                            border-bottom: 1px solid #FFF1D2
                        }
                    }
                }
            }
        }
    }
}